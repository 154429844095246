<template>
  <div class="mainContent">
    <div class="details" id="details">
      <div class="topNav44" v-show="Show">
        <ul>
          <a @click="Click(0)" :style="{ color: num == 0 ? '#fff' : '#ccc', cursor: 'pointer' }">{{
              $t("C2.v13")
            }}</a>
          <a :style="{ color: num != 0 ? '#fff' : '#ccc',cursor: 'pointer' }" @click="Click(1)">{{
              $t("C2.v14")
            }}</a>
          <a
              style="color: #ccc; margin-left: 0.55rem; cursor: pointer"
              @click="Click(9)"
          >{{ $t("C2.v14a") }}</a
          >
        </ul>
      </div>
      <div class="content1" id="co1">
        <div class="c1">
          <div class="item c1-item1">
            <div class="c1-item1-1">
              <!-- <img
              src="../../../../assets/image/accessories/arrows-left.png"
              alt=""
            /> -->
            </div>
            <div class="c1-item1-2">
              <img
                src="../../../../assets/image/accessories/accessories.png"
                alt=""
              />
            </div>
            <div class="c1-item1-1">
              <!-- <img
              src="../../../../assets/image/accessories/arrows-right.png"
              alt=""
            /> -->
            </div>
          </div>
          <div class="item c1-item2">
            <div>
              <div class="p1">{{ $t("details.v1") }}</div>
              <!-- <div class="plice">￥299.00</div> -->
              <div class="line"></div>
              <span class="color"> {{ $t("details.v2") }} </span>
              <div class="option">
                <div class="option-1">
                  <div></div>
                  <div>{{ $t("details.v215") }}</div>
                </div>
                <!-- <div class="option-1">
              <div></div>
              <div>经典黑</div>
            </div> -->
              </div>
              <!-- <div class="line"></div> -->
              <!--<button>立即购买</button>-->
            </div>
          </div>
        </div>
        <div class="c2">
          <div>
            <template v-for="(item, index) in info">
              <div class="c2-item" :key="'c2-' + index">
                <div>
                  <div class="img1">
                    <img :class="'img-1' + index" :src="item.img1" alt="" />
                  </div>
                  <span v-if="index !== 0">{{ $t(item.text1) }}</span>
                  <span class="sp1" v-else>
                    <span class="sp2"
                      >{{ $t(item.text1) }}<sup>「1」</sup></span
                    >
                  </span>
                </div>
                <div>
                  <div class="img1">
                    <img :class="'img-2' + index" :src="item.img2" alt="" />
                  </div>
                  <span>{{ $t(item.text2) }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="content2" id="content2">
        <div class="topNav" id="toper" ref="toper">
          <ul>
            <a @click="Click(0)" :style="{ color: num == 0 ? '#fff' : '#ccc', cursor: 'pointer' }">{{
                $t("C2.v13")
              }}</a>
            <a :style="{ color: num != 0 ? '#fff' : '#ccc',cursor: 'pointer' }" @click="Click(3)">{{
                $t("C2.v14")
              }}</a>
            <a
                style="color: #ccc; margin-left: 0.55rem; cursor: pointer"
                @click="Click(9)"
            >{{ $t("C2.v14a") }}</a
            >
          </ul>
        </div>
        <div class="content2-item">
          <!-- <span>精准充气的迅猛龙</span> -->
          <div>
            <video
              class="video"
              ref="audios"
              id="audios"
              poster="https://image.iriding.cc/video/detailVideo.png"
              x5-video-player-type="h5-page"
              webkit-playsinline="true"
              playsinline="true"
              disablePictureInPicture
              muted
              controls
              controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
            >
              <source
                src="https://image.iriding.cc/video/detailVideo.mp4"
                type="video/mp4"
              />
              您的浏览器不支持Video标签。
            </video>
          </div>
          <div
            class="play"
            v-show="isplay"
            v-if="browserVisible"
            @click="isPlay()"
          >
            <img src="../../../../assets/image/accessories/play1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="content3">
        <template v-for="(item, index) in imgs">
          <div
            class="items"
            :style="{
              backgroundImage: 'url(' + item.img + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }"
            :key="'img-' + index"
          >
            <div class="img1" v-if="index === 0">
              <div class="img1-item1">
                <span>{{ $t("details.v1611a") }}</span>
                <span>{{ $t("details.v1621") }}</span>
                <span>{{ $t("details.v1631b") }}</span>
                <br />
                <span>{{ $t("details.v1711a") }}</span>
                <span>5</span>
                <span>{{ $t("details.v1721a") }}</span>
              </div>
              <div class="img1-item2">
                <div class="sp1">
                  <img
                    src="../../../../assets/image/accessories/(71).png"
                    alt=""
                  />
                  <span class="sp2">
                    <span class="sp3">{{ $t("details.v16a") }} </span>
                    <span class="two">「2」</span>
                  </span>
                </div>
                <span class="sp">{{ $t("details.v14") }}</span>
                <br />
                <span class="sp">{{ $t("details.v998") }}</span>
                <p>{{ $t("details.v15") }}{{ $t("details.v16") }}</p>
              </div>
            </div>
            <div class="img2" v-if="index === 1">
              <div class="img2-item1">
                <img
                  src="../../../../assets/image/accessories/(8).png"
                  alt=""
                />
                <span>{{ $t("details.v19") }}</span>
              </div>
              <div class="img2-item2">
                <span>{{ $t("details.v216") }}</span>
                <br />
                <span>{{ $t("details.v18") }}</span>
              </div>
              <div class="img2-item3">
                <span
                  >{{ $t("details.v20") }}{{ $t("details.v21")
                  }}{{ $t("details.v22") }}</span
                >
              </div>
            </div>
            <div class="img3" v-if="index === 2">
              <div class="img3-item1">
                <span
                  >{{ $t("details.v261") }}
                  <span>{{ $t("details.v262") }}</span>
                  {{ $t("details.v263") }}</span
                >
                <br />
                <span class="sp2">
                  {{ $t("details.v9971") }}
                  <span>{{ $t("details.v9972") }}</span>
                  {{ $t("details.v9973") }}
                </span>
              </div>
              <div class="img3-item2">
                <div>
                  <img
                    src="../../../../assets/image/accessories/big-img3.png"
                    alt=""
                  />
                  <span>{{ $t("details.v9") }}</span>
                </div>
                <span>{{ $t("details.v23") }} </span>
                <br />
                <span>{{ $t("details.v231") }}</span>
                <p>
                  {{ $t("details.v24") }}{{ $t("details.v25")
                  }}{{ $t("details.v26") }}
                </p>
              </div>
            </div>
            <div class="img4" v-if="index === 3">
              <div class="img4-item1">
                <img
                  src="../../../../assets/image/accessories/(61).png"
                  alt=""
                />
                <span>{{ $t("details.v31") }}{{ $t("details.v311") }}</span>
              </div>
              <div class="img4-item2">
                <div>{{ $t("details.v272") }}</div>

                <div>{{ $t("details.v271") }}</div>
              </div>
              <div class="img4-item3">
                <span
                  >{{ $t("details.v28") }}{{ $t("details.v29") }}
                  <br />
                  {{ $t("details.v30") }}</span
                >
              </div>
            </div>
            <div class="img5" v-if="index === 4">
              <div class="img5-item1">
                <div>
                  <img
                    src="../../../../assets/image/accessories/big-img5.png"
                    alt=""
                  />
                  <span>{{ $t("details.v176") }}</span>
                </div>
                <span class="sp1">
                  <span class="sp2">{{ $t("details.v177") }}</span>
                  <span class="three">「3」</span>
                </span>
                <br />
                <p>
                  {{ $t("details.v178") }}
                </p>
              </div>
              <div class="img5-item2">
                <span>{{ $t("details.v191") }}</span>
                <br />
                <span
                  >{{ $t("details.v192") }}
                  <img
                    id="img"
                    src="../../../../assets/image/accessories/big-img5.png"
                    alt=""
                  />
                  {{ $t("details.v193") }}</span
                >
              </div>
            </div>
          </div>
        </template>
        <!--      5种使用模式-->
        <div class="items2">
          <img src="../../../../assets/image/accessories/(4).png" alt="" />
          <span class="items2-p1">{{ $t("details.v12") }}</span>
          <span class="items2-p2">{{ $t("details.v213") }}</span>
          <span class="items2-p3"
            >{{ $t("details.v33") }}{{ $t("details.v34")
            }}{{ $t("details.v35") }}</span
          >
        </div>
        <div class="items3">
          <template v-for="(item, index) in imgs">
            <div
              :style="{
                backgroundImage: 'url(' + item.img2 + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: '100% auto',
              }"
              class="items3-1"
              :key="'item-' + index"
            >
              <div>
                <div class="img1">
                  <img :src="item.img3" alt="" />
                  <span class="p1">{{ $t(item.text1) }}</span>
                </div>
                <div class="img2" style="text-align: center">
                  <span class="p2 t2">{{ $t(item.text2) }}</span>
                  <span class="p1" v-if="index == 4"
                    >{{ $t(item.text3) }}<br />{{ $t(item.text4) }}</span
                  >
                  <span class="p1" v-else>{{ $t(item.text3) }}</span>
                </div>
                <div class="img3">
                  <span class="p2 t2" v-if="index != 4"
                    >{{ $t(item.text4) }}
                  </span>
                  <span class="p2 t2" v-if="index === 4">&nbsp;&nbsp; </span>
                  <span v-if="item.text5 !== ''" class="p1">{{
                    $t(item.text5)
                  }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="items4">
          <div class="top">
            <img
              src="../../../../assets/image/accessories/buttery-small.png"
              alt=""
            />
            <span>{{ $t("details.v47") }}</span>
          </div>
          <div class="text2">
            {{ $t("details.v214") }}{{ $t("details.v48") }}
          </div>
          <div class="text3">
            {{ $t("details.v49") }}{{ $t("details.v50")
            }}{{ $t("details.v51") }}
          </div>
          <img
            class="img2"
            src="../../../../assets/image/accessories/buttery.png"
            alt=""
          />
        </div>
      </div>
      <div class="content6">
        <div class="content1">
          <div>
            <span class="item1">{{ $t("details.v52") }}</span>
            <div class="item2">
              <template v-for="(item, index) in mes">
                <div :key="'item2-' + index">
                  <div class="item2-1">
                    <div
                      class="item2-right"
                      :style="{
                        backgroundImage: 'url(' + item.img + ')',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% auto',
                      }"
                    >
                      <div class="sm-icon" v-if="index === 1">
                        <template v-for="(item, index) in iconInfo">
                          <div :key="`icon${index}`">
                            <img :src="item.img" alt="" />
                            <div>{{ $t(item.text) }}</div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="item2-left">
                      <span v-if="index !== 1" class="item2-left-top">{{
                        $t(item.text1)
                      }}</span>
                      <span v-else class="item2-left-top sp1">
                        <span class="sp2">{{ $t(item.text1) }}</span>
                        <span class="four">「4」</span>
                      </span>
                      <div class="item2-left-bottom">
                        <span>{{ $t(item.text2) }}</span>
                        <br />
                        <span>{{ $t(item.text3) }}</span>
                      </div>
                    </div>
                  </div>
                  <div :key="'item2-' + index" class="item2-1">
                    <div
                      class="item2-right"
                      :style="{
                        backgroundImage: 'url(' + item.img2 + ')',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '100% auto',
                      }"
                    ></div>
                    <div class="item2-left">
                      <span class="item2-left-top">{{ $t(item.text4) }}</span>
                      <div class="item2-left-bottom">
                        <span>{{ $t(item.text5) }}</span>
                        <br />
                        <span>{{ $t(item.text6) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="content2">
          <span class="item1">
            <span
              ><span>{{ $t("details.v2121") }}<sup>「5」</sup></span>
            </span>
            <span style="margin-left: 0">{{ $t("details.v2122") }}</span>
          </span>
          <div class="item2">
            <template v-for="(item, index) in c6">
              <div
                :key="'item3-' + index"
                class="borderTop borderLeft"
                :class="
                  [5, 6, 4].includes(index)
                    ? 'borderBottom'
                    : [3].includes(index)
                    ? 'borderRight'
                    : [7].includes(index)
                    ? 'borderRight borderBottom'
                    : ''
                "
              >
                <div class="img1">
                  <img :class="'img' + index" :src="item.img" alt="" />
                </div>

                <span>{{ $t(item.text1) }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="content4" id="c4">
        <div class="content4-1">
          <span class="item1">{{ $t("details.v76") }}</span>
          <div class="item2">{{ $t("details.v77") }}</div>
          <div class="d1">
            <!-- <div class="item3">
            <div class="item3-1">充气对象</div>
            <div class="item3-2">
              <div>
                <span>充气压力</span>
                <span>满电可充</span>
                <span>充气速度</span>
              </div>
            </div>
          </div> -->
            <div class="item3">
              <div class="item3-1">{{ $t("details.v78") }}</div>
              <div class="item3-2">
                <div>
                  <span>
                    <span>{{ $t("details.v108") }}<sup>「6」</sup></span>
                  </span>
                  <span>{{ $t("details.v119") }}</span>
                  <span>{{ $t("details.v134") }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d2">
            <template v-for="(item, index) in referInfo">
              <div class="item4" :key="'item4-' + index">
                <div class="item4-1">
                  <div class="img1">
                    <img :class="'img' + index" :src="item.img" alt="" />
                  </div>

                  <span class="item4-text1">{{ $t(item.text1) }}</span>
                  <span class="item4-text2">{{ $t(item.text2) }}</span>
                </div>
                <div class="item4-2">
                  <div>
                    <span>{{ $t(item.text3) }}</span>
                    <span>{{ $t(item.text4) }}</span>
                    <span>{{ $t(item.text5) }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="d3">
          <div>
            <template v-for="(item, index) in obj">
              <span :key="'span-' + index">
                {{ $t(item.text1) }}&nbsp;{{ $t(item.text) }}</span
              >
              <br :key="'br-' + index" />
            </template>
          </div>
        </div>
      </div>
      <div class="de" id="de"></div>
      <!--    规格参数-->
      <div class="content5" id="c5">
        <p>{{ $t("details.v195") }} {{ $t("details.v5") }}</p>
        <div class="items1">
          <div class="item-content">
            <div class="item-content-left">
              <div>
                <div class="img1">
                  <img
                    src="../../../../assets/image/accessories/c5-1.png"
                    alt=""
                  />
                  <img
                    src="../../../../assets/image/accessories/tab-bar.png"
                    alt=""
                  />
                </div>
                <img
                  class="img2"
                  src="../../../../assets/image/accessories/tab-barx.png"
                  alt=""
                />
              </div>
            </div>
            <div class="item-content-right">
              <table border="1" cellspacing="0">
                <template v-for="(item, index) in c5">
                  <tr :key="'tr-' + index">
                    <td style="text-align: center">
                      {{ $t(item.text1) }}
                    </td>
                    <td style="padding-left: 57px; padding-right: 30px">
                      {{ $t(item.text2) }}
                      <span v-if="index == 2">
                        <br />{{ $t(item.text3) }}
                      </span>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
        <!--      包装清单-->
        <div class="items2">
          <div>
            <div class="p">{{ $t("details.v175") }}</div>
            <div class="footer">
              <template v-for="(item, index) in footer">
                <div :key="'footer-' + index" class="img">
                  <img :class="'img' + index" :src="item.img" alt="" />
                </div>
              </template>
            </div>
            <div class="footer2">
              <template v-for="(item, index) in footer">
                <div :key="'foo' + index" class="text1">
                  <div>{{ $t(item.text1) }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <to-top />
    </div>
  </div>
</template>


<script>
import ToTop from "../../component/totop/toTop.vue";
import util from "@/util.js";
export default {
  name: "details",
  components: { ToTop },
  data() {
    return {
      isplay: true,
      browserVisible: true,
      info: [
        {
          img1: require("../../../../assets/image/accessories/@2x(5).png"),
          text1: "details.v8",
          img2: require("../../../../assets/image/accessories/@2x(6).png"),
          text2: "details.v11",
        },
        {
          img1: require("../../../../assets/image/accessories/@2x(9).png"),
          text1: "details.v24a",
          img2: require("../../../../assets/image/accessories/@2x(4).png"),
          text2: "details.v12",
        },
        {
          img1: require("../../../../assets/image/accessories/@2x(7).png"),
          text1: "details.v10",
          img2: require("../../../../assets/image/accessories//@2x(2).png"),
          text2: "details.v13",
        },
      ],
      imgs: [
        {
          img: require("../../../../assets/image/accessories/sands.png"),
          id: 1,
          img2: require("../../../../assets/image/accessories/imgs-12.png"),
          img3: require("../../../../assets/image/accessories/17.png"),
          text1: "details.v361",
          text2: "details.v362",
          text3: "details.v363",
          text4: "details.v39",
          text5: "details.v371",
        },
        {
          img: require("../../../../assets/image/accessories/cars.png"),
          id: 2,
          img2: require("../../../../assets/image/accessories/imgs-13.png"),
          img3: require("../../../../assets/image/accessories/18.png"),
          text1: "details.v38",
          text2: "details.v362",
          text3: "details.v364",
          text4: "details.v39",
          text5: "details.v391",
        },
        {
          img: require("../../../../assets/image/accessories/baicyle.png"),
          id: 3,
          img2: require("../../../../assets/image/accessories/imgs-14.png"),
          img3: require("../../../../assets/image/accessories/19.png"),
          text1: "details.v40",
          text2: "details.v362",
          text3: "details.v411",
          text4: "details.v39",
          text5: "details.v413",
        },
        {
          img: require("../../../../assets/image/accessories/balls.png"),
          id: 4,
          img2: require("../../../../assets/image/accessories/imgs-15.png"),
          img3: require("../../../../assets/image/accessories/20.png"),
          text1: "details.v42",
          text2: "details.v362",
          text3: "details.v421",
          text4: "details.v39",
          text5: "details.v43",
        },
        {
          img: require("../../../../assets/image/accessories/motocyle.png"),
          id: 5,
          img2: require("../../../../assets/image/accessories/imgs-16.png"),
          img3: require("../../../../assets/image/accessories/21.png"),
          text1: "details.v44",
          text2: "details.v39",
          text3: "details.v451a",
          text4: "details.v451b",
          text5: "",
          text6: "",
        },
      ],
      mes: [
        {
          img: require("../../../../assets/image/accessories/mes-8.png"),
          text1: "details.v53",
          text2: "details.v54",
          text3: "details.v55",
          img2: require("../../../../assets/image/accessories/mes-9.png"),
          text4: "details.v56",
          text5: "details.v57",
          text6: "details.v58",
        },
        {
          img: require("../../../../assets/image/accessories/mes-10.png"),
          text1: "details.v59",
          text2: "details.v60",
          text3: "details.v61",
          img2: require("../../../../assets/image/accessories/mes-11.png"),
          text4: "details.v62",
          text5: "details.v63",
          text6: "details.v631",
        },
        {
          img: require("../../../../assets/image/accessories/mes-12.png"),
          text1: "details.v64",
          text2: "details.v65",
          text3: "details.v66",
        },
      ],
      c5: [
        {
          text1: "details.v194",
          text2: "details.v195",
        },
        {
          text1: "details.v196",
          text2: "details.v197",
        },
        {
          text1: "details.v198",
          text2: "details.v199a",
          text3: "details.v199b",
        },
        {
          text1: "details.v200",
          text2: "details.v201",
        },
        {
          text1: "details.v202",
          text2: "details.v203",
        },
        {
          text1: "details.v204",
          text2: "details.v205",
        },
        // {
        //   text1: "details.v206",
        //   text2: "±1psi ",
        // },
        {
          text1: "details.v208",
          text2: "details.v209",
        },
        /*{
          text1: "details.v210",
          text2: "5V---3A",
        },*/
      ],
      footer: [
        {
          img: require("../../../../assets/image/accessories/footer-1.png"),
          text1: "details.v174",
        },
        {
          img: require("../../../../assets/image/accessories/footer-2.png"),
          text1: "details.v173",
        },
        {
          img: require("../../../../assets/image/accessories/footer-3.png"),
          text1: "details.v172",
        },
        {
          img: require("../../../../assets/image/accessories/footer-4.png"),
          text1: "details.v171",
        },

        {
          img: require("../../../../assets/image/accessories/footer-5.png"),
          text1: "details.v170",
        },
        {
          img: require("../../../../assets/image/accessories/footer-6.png"),
          text1: "details.v169",
        },
        {
          img: require("../../../../assets/image/accessories/footer-7.png"),
          text1: "details.v168",
        },
      ],
      c6: [
        {
          img: require("../../../../assets/image/accessories/c6-1.png"),
          text1: "details.v68",
        },
        {
          img: require("../../../../assets/image/accessories/c6-2.png"),
          text1: "details.v69",
        },
        {
          img: require("../../../../assets/image/accessories/c6-3.png"),
          text1: "details.v74",
        },
        {
          img: require("../../../../assets/image/accessories/c6-4.png"),
          text1: "details.v70",
        },
        {
          img: require("../../../../assets/image/accessories/c6-5.png"),
          text1: "details.v71",
        },
        {
          img: require("../../../../assets/image/accessories/c6-6.png"),
          text1: "details.v75",
        },
        {
          img: require("../../../../assets/image/accessories/c6-7.png"),
          text1: "details.v72",
        },
        {
          img: require("../../../../assets/image/accessories/c6-8.png"),
          text1: "details.v73",
        },
      ],
      referInfo: [
        {
          img: require("../../../../assets/image/accessories/c4-1o.png"),
          text1: "details.v79",
          text2: "details.v80",
          text3: "details.v109",
          text4: "details.v120",
          text5: "details.v135",
        },
        {
          img: require("../../../../assets/image/accessories/c4-3.png"),
          text1: "details.v81",
          text2: "details.v82",
          text3: "details.v110",
          text4: "details.v121",
          text5: "details.v136",
        },
        {
          img: require("../../../../assets/image/accessories/c4-7.png"),
          text1: "details.v83",
          text2: "details.v84",
          text3: "details.v110",
          text4: "details.v122",

          text5: "details.v137",
        },
        {
          img: require("../../../../assets/image/accessories/c4-2.png"),
          text1: "details.v85",
          text2: "details.v86",
          text3: "details.v110",
          text4: "details.v122",
          text5: "details.v138",
        },
        {
          img: require("../../../../assets/image/accessories/c4-2.png"),
          text1: "details.v87",
          text2: "details.v88",
          text3: "details.v110",
          text4: "details.v123",
          text5: "details.v139",
        },
        {
          img: require("../../../../assets/image/accessories/c4-11.png"),
          text1: "details.v89",
          text2: "details.v90",
          text3: "details.v110",
          text4: "details.v124",

          text5: "details.v140",
        },
        {
          img: require("../../../../assets/image/accessories/c4-5.png"),
          text1: "details.v91",
          text2: "details.v92",
          text3: "details.v110",
          text4: "details.v123",

          text5: "details.v141",
        },
        {
          img: require("../../../../assets/image/accessories/c4-15.png"),
          text1: "details.v93",
          text2: "details.v94",
          text3: "details.v111",
          text4: "details.v125",
          text5: "details.v142",
        },
        {
          img: require("../../../../assets/image/accessories/cm.png"),
          text1: "details.v93",
          text2: "details.v96",
          text3: "details.v111",
          text4: "details.v126",
          text5: "details.v143",
        },

        {
          img: require("../../../../assets/image/accessories/c4-4.png"),
          text1: "details.v97",
          text2: "details.v98",
          text3: "details.v112",
          text4: "details.v127",
          text5: "details.v144",
        },

        {
          img: require("../../../../assets/image/accessories/c4-6.png"),
          text1: "details.v99",
          text2: "details.v100",
          text3: "details.v113",
          text4: "details.v128",
          text5: "details.v145",
        },

        {
          img: require("../../../../assets/image/accessories/c4-8.png"),
          text1: "details.v101",
          text2: "details.v102",
          text3: "details.v114",
          text4: "details.v129",
          text5: "details.v146",
        },

        {
          img: require("../../../../assets/image/accessories/c4-10.png"),
          text1: "details.v103",
          text2: "details.v104",
          text3: "details.v115",
          text4: "details.v130",
          text5: "details.v147",
        },

        {
          img: require("../../../../assets/image/accessories/c4-12.png"),
          text1: "details.v105",
          text2: "",
          text3: "details.v116",
          text4: "details.v131",
          text5: "details.v148",
        },

        {
          img: require("../../../../assets/image/accessories/c4-14.png"),
          text1: "details.v106",
          text2: "",
          text3: "details.v117",
          text4: "details.v132",
          text5: "details.v149",
        },

        {
          img: require("../../../../assets/image/accessories/c4-16.png"),
          text1: "details.v107",
          text2: "",
          text3: "details.v118",
          text4: "details.v133",
          text5: "details.v150",
        },
      ],
      iconInfo: [
        {
          img: require("../../../../assets/image/accessories/sm-1.png"),
          text: "details.v611",
        },
        {
          img: require("../../../../assets/image/accessories/sm-2.png"),
          text: "details.v612",
        },
        {
          img: require("../../../../assets/image/accessories/sm-3.png"),
          text: "details.v613",
        },
      ],
      obj: [
        {
          text1: "details.v1510",
          text: "details.v1570",
        },
        {
          text1: "details.v1520",
          text: "details.v1580",
        },
        {
          text1: "details.v1530",
          text: "details.v1590",
        },
        {
          text1: "details.v1540",
          text: "details.v1591",
        },
        {
          text1: "details.v1550",
          text: "details.v1592",
        },
        {
          text1: "details.v1560",
          text: "details.v1593",
        },
        {
          text1: "",
          text: "details.v1594",
        },
      ],
      activeIndex: false,
      Show: false,
      scrollTop: "",
      toper: "",
      indexNumTop: "",
      num: 0,
      color: false,
    };
  },
  mounted() {
    this.$store.state.isShowFooter = true;
    window.addEventListener("scroll", this.getScroll);
    this.$refs.audios.addEventListener("play", this.handlePlay);
    this.$refs.audios.addEventListener("pause", this.handlePause);
    if (util.isFirefox() || util.isSafari()) {
      this.browserVisible = false;
    } else {
      this.browserVisible = true;
    }
    let timer = null;
    setTimeout(() => {
      this.gg();
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        this.gg();
      }, 500);
    };
  },
  methods: {
    handlePlay() {
      this.$refs.audios.play();
      this.isplay = false;
    },
    handlePause() {
      this.$refs.audios.pause();
      this.isplay = true;
    },
    isPlay() {
      let myVideo = document.querySelector("#audios");
      if (myVideo.paused) {
        this.isplay = false;
        myVideo.play();
      } else {
        this.isplay = true;
        myVideo.paused();
      }
    },
    getScroll() {
      this.isShow = false;
      this.indexNumTop = document
        .querySelector("#toper")
        .getBoundingClientRect().top;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      document.querySelector("#de").style.height = "0px";
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop >= 1200
      ) {
        this.Show = true;
        this.$store.state.isShowHeader = false;
      } else if (
        this.num === 0 &&
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop >= 1000
      ) {
        this.Show = false;
        this.$store.state.isShowHeader = false;
      } else {
        this.Show = false;
        this.$store.state.isShowHeader = true;
      }
    },
    Click(num) {
      this.num = num;

      if (num == 9) {
        let name = 'P1.pdf';
        if (this.$store.state.lang != 'zh-cn' && this.$store.state.lang != 'zh-hk') {
          name = 'P1G.pdf';
        }
        const routeUrl = this.$router.resolve({
          path: "/pdf",
          query: { url: `https://image.iriding.cc/manual/${name}` },
        });

        window.open(routeUrl.href, "_blank");
        return;
      }

      if (num === 0) {
        document.getElementById("content2").scrollIntoView();
        this.num = 0;
      } else if (num === 3) {
        document.querySelector("#de").style.height = "80px";
        this.color = true;
        document.getElementById("de").scrollIntoView();
      } else if (num === 1) {
        document.getElementById("de").scrollIntoView();
        // document.querySelector("#de").style.height = "80px";
        this.color = true;
      }
    },
    gg() {
      let designSize = 1920; // 设计图尺寸
      //let html = document.documentElement;

      // 浏览器宽度
      let rem = (document.documentElement.clientWidth * 100) / designSize;

      document.documentElement.style.fontSize = rem + "px";
      console.log(12, document.documentElement.clientWidth);
    },
    fnResize() {
      var deviceWidth =
        document.documentElement.clientWidth || window.innerWidth;
      console.log(8, deviceWidth);
      let bool = "";
      if (deviceWidth >= 1920) {
        deviceWidth = 1920;
        bool = 19.2;
      } else if (deviceWidth <= 1550) {
        deviceWidth = 1550;
        bool = 15.5;
      } else if (deviceWidth <= 1440) {
        deviceWidth = 1440;
        bool = 14.4;
      } else if (deviceWidth <= 1280) {
        deviceWidth = 1280;
        bool = 12.8;
      } else if (deviceWidth <= 980) {
        deviceWidth = 980;
        bool = 9.8;
      }

      document.documentElement.style.fontSize = deviceWidth / bool + "px";
      let g = document.documentElement;
      console.log(3, g);
    },
    setItem() {
      let height = this.$refs.fullpage0[0].offsetHeight;
      let filterH2 =
        this.$refs.top0[0].offsetHeight + this.$refs.top0[0].offsetTop;
      let filterH3 =
        height -
        this.$refs.bottom0[0].offsetHeight -
        this.$refs.bottom0[0].offsetTop;
      let h = (height - filterH2 - filterH3) * 0.8;
      let ii = this.$refs.imgB0[0];
      let lv = ii.offsetWidth;
      let lv1 = ii.offsetHeight;
      // 图片宽高比
      let bi = 1542 / 1080;
      let box = lv / lv1;
      if (box >= bi) {
        this.imgBigHeight = h + "px";
        this.imgWidth = "auto";
        this.imgHeight = "100%";
      } else {
        this.imgBigHeight = h + "px";
        this.imgWidth = "90%";
        this.imgHeight = "auto";
      }
      this.isShow = true;
      // 判断宽高比
    },
  },
};
</script>

<style scoped lang="less">
#de {
  padding-top: 50px;
  margin-top: -55px;
}
sup {
  font-size: 60%;
  position: relative;
  top: -5px;
}
/*@import "../../../../assets/less/screen1920.less";
@import "../../../../assets/less/screen1550.less";
@import "../../../../assets/less/screen1440.less";
@import "../../../../assets/less/screen1280.less";*/
//@import "../../../../assets/less/origin1920.less";
@import "../../../../assets/less/screen1920.less";
</style>
